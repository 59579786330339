<template>
    <div class="platform_selector">
        <el-checkbox-group v-model="checkList" @change="checkListChange">
            <el-checkbox :label="1" :disabled="disableList[0]">
                <img src="@/assets/img/companyDetails/baidu_search.png" alt="">
                <span class="text">百度PC</span>
            </el-checkbox>
            <el-checkbox :label="2" :disabled="disableList[1]">
                <img src="@/assets/img/companyDetails/baidu_mobile.png" alt="">
                <span class="text">百度移动</span>
            </el-checkbox>
            <el-checkbox :label="3" :disabled="disableList[2]">
                <img src="@/assets/img/companyDetails/360_search.png" alt="">
                <span class="text">360PC</span>
            </el-checkbox>
            <el-checkbox :label="4" :disabled="disableList[3]">
                <img src="@/assets/img/companyDetails/360_mobile.png" alt="">
                <span class="text">360移动</span>
            </el-checkbox>
            <el-checkbox :label="5" :disabled="disableList[4]">
                <img src="@/assets/img/companyDetails/sougou_search.png" alt="">
                <span class="text">搜狗PC</span>
            </el-checkbox>
            <el-checkbox :label="6" :disabled="disableList[5]">
                <img src="@/assets/img/companyDetails/sougou_mobile.png" alt="">
                <span class="text">搜狗移动</span>
            </el-checkbox>
            <el-checkbox :label="7" :disabled="disableList[6]">
                <img src="@/assets/img/companyDetails/shenma_search.png" alt="">
                <span class="text">神马搜索</span>
            </el-checkbox>
        </el-checkbox-group>
    </div>
</template>

<script>
  export default {
    name: "platformSelector",
    props: ['list'],
    data() {
      return {
        checkList: [],
        disableList: [true, true, true, true, true, true, true]
      }
    },
    watch: {
      list(newList, oldList) {
        this.changeVersion(newList)
      }
    },
    methods: {
      checkListChange(list) {
        this.$emit('childChange', this.checkList);
      },
      clear() {
        this.checkList = [];
        // this.checkListChange()
      },
      changeVersion(list){
        for (var i = 0; i < list.length; i++) {
          this.$set(this.disableList, list[i]-1, false)
        }
      }
    }
  }
</script>

<style scoped lang="scss">
.platform_selector {
    .title {
        font-size: 14px;
        color: #999999;
    }
}
</style>

<style>
.platform_selector .el-checkbox__label {
    padding-left: 7px;
    font-size: 14px;
    color: #333333;
}
.platform_selector .el-checkbox__label img {
    width: 14px;
    height: 14px;
}
.platform_selector .el-checkbox__label .text {
    font-size: 14px;
    color: #333333;
    margin-left: 4px;
}
.platform_selector .el-checkbox {
    display: inline-flex;
    align-items: center;
    margin-right: 28px;
}
.platform_selector .el-checkbox__input.is-disabled+span.el-checkbox__label .text{
    color: #c9bec5;
}
</style>